
<template>
    <Klanttoevoegen />
  </template>
  
  <script>
  import Klanttoevoegen from "@/components/klanten/Klanttoevoegen";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        Klanttoevoegen,
    },
    metaInfo: {
   title: "Klant Toevoegen",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>