
<template>
  <Klanten />
</template>

<script>
import Klanten from "@/components/klanten/Klanten";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Klanten,
  },
  metaInfo: {
 title: "Klanten overzicht",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>