<template>
  <v-container>
    <form ref="form" @submit.prevent="addTrader">
      <v-stepper v-model="e13">
        <v-stepper-step :complete="e13 > 1" step="1"
          >Basisinformatie</v-stepper-step
        >
        <v-stepper-content step="1">
          <v-card class="mb-5">
            <v-card-title>Basisinformatie</v-card-title>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  dense
                  label="Type (Particulier/Zakelijk)"
                  v-model="form.is_private"
                  :items="types"
                  item-text="text"
                  item-value="value"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  dense
                  label="Initialen"
                  v-model="form.initials"
                  :items="items"
                  item-text="text"
                  item-value="value"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="Voornaam"
                  v-model="form.first_name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="Achternaam"
                  v-model="form.last_name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="Email"
                  v-model="form.email"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="Telefoonnummer"
                  v-model="form.phonenumber"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click.prevent="e13 = 2"
                  style="margin-right: 5px;"
                  >Volgende</v-btn
                >

                <v-btn color="error" @click="goBack()">Terug</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="2">Adres en Overige Informatie</v-stepper-step>
        <v-stepper-content step="2">
          <v-card>
            <v-card-title>Adres en Overige Informatie</v-card-title>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="Adres"
                  v-model="form.address_street"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="Postcode"
                  v-model="form.address_postal_code"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="Stad"
                  v-model="form.address_city"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="BTW-nummer"
                  v-model="form.vat_number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="Website"
                  v-model="form.website"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="Referentie"
                  v-model="form.reference"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  type="submit"
                  style="margin-right: 5px;"

                  >Klant updaten</v-btn
                >
                <v-btn color="error" @click.prevent="e13 = 1">Terug</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </form>
  </v-container>
</template>

<script>
import apiKlanten from "../api/apiKlanten";
export default {
  data() {
    return {
      e13: 1,
      types: ["Zakelijk", "Prive"],
      items: ["Man", "Vrouw", "Onbekend"],
      form: {
        is_private: null,
        address_street: "",
        address_postal_code: "",
        address_city: "",
        address_country_code: "NL",
        shift_vat: true,
        vat_number: "",
        initials: "",
        first_name: "",
        attn: "",
        phonenumber: "",
        website: "",
        email: "",
        invoice_language: "nl",
        reference: "",
      },
    };
  },

 async mounted(){
await this.fetchKlant();
  },

  methods: {
    goBack() {
      window.history.back();
    },
    
    async fetchKlant(){
  try {
    const id = this.$route.params.id;
    const data = await apiKlanten.haalEenKlantOp(id);
    this.form = data; 
  } catch (error) {
    console.error("Er is een fout opgetreden bij het ophalen van de klanten", error);
  }
},
  },
};
</script>
