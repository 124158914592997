<template>
  <div>
    <v-container>
      
      <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
      <div v-else>
      <v-row>
        <v-col cols="12">
          <v-container>
            <v-card>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    v-on="on"
                    style="margin-left:10px;margin-top:10px"
                  >
                    Exporteren
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in exportOptions"
                    :key="item.text"
                    @click="exportData(item.type)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-card-title>
           Klanten
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Opzoeken"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <div class="table-container">
                <v-data-table
                  :fixed-header="true"
                   height="100vh"
                  :headers="headers"
                  :items="klanten"
                  :search="search"
                  disable-pagination
                  :hide-default-footer="true"
                  class="elevation-1"
                  id="my-traders"
                  cellClass="printable"
                >
                  <template v-slot:[`item.Actions`]="{ item }">
                    <v-icon small color="green" @click="openKlant(item.id)">
                      mdi-eye
                    </v-icon>
                    <v-icon small color="red" @click="verwijderKlant(item.id)">
                      mdi-delete
                    </v-icon>
                 
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import "jspdf-autotable";

import * as XLSX from "xlsx";
import apiKlanten from "../api/apiKlanten";
export default {
  data: () => ({
    isLoading: true,
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],
    klanten: [],
    search: "",
    headers: [
      {
        text: "Klantnaam",
        value: "customer_name",
        sortable: true,
      },
      {
        text: "Adres",
        value: "address_street",
        sortable: true,
      },
      {
        text: "Email",
        value: "email",
        sortable: true,
      },

      {
        text: "Telefoonummer",
        value: "phonenumber",
        sortable: true,
      },
      
      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    await this.fetchKlanten();
  },

  methods: {
    async fetchKlanten(){
  try {
    const data = await apiKlanten.haalKlantenOp();
    this.klanten = data;
    this.isLoading = false;
  } catch (error) {
    console.error("Er is een fout opgetreden bij het ophalen van de klanten", error);
    this.isLoading = false; 
  }
},
    openKlant(id) {
      this.$router.push(`/klantbekijken/${id}`);
    },
    async verwijderKlant(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze klant wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiKlanten.deleteTrader(id);
        this.fetchKlanten();
        this.$swal.fire(
          "Verwijderd",
          "De Klant is succesvol verwijderd",
          "success"
        );
      }
    },

    exportData(type) {
      if (type === "excel") {
        const data = XLSX.utils.json_to_sheet(this.klanten);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, "klantenoverzicht.xlsx");
      }  else if (type === "print") {
        const printContents = document.getElementById("my-traders").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
  },
};
</script>

<style>
.table-container {
  height: 500px; /* Hoogte van de tabelcontainer */
  overflow-y: auto; /* Scrollen toestaan */
}

.v-data-table__overflow {
  overflow: hidden !important; /* Verborgen overflow voor de tabelinhoud */
}
</style>
