
<template>
  <Klantbekijken />
</template>

<script>
import Klantbekijken from "@/components/klanten/Klantbekijken";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Klantbekijken,
  },
  metaInfo: {
 title: "Klant bekijken",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>