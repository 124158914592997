<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-col>
        <v-btn
          color="primary"
          style="margin-right:5px"
          @click="klantaanmaken()"
        >
          Klanten toevoegen
        </v-btn>

        <div style="float:right">
          <v-icon
            :color="showTable ? 'primary' : undefined"
            @click="
              showTable = true;
              showMap = false;
            "
            >mdi-table</v-icon
          >
          <v-icon
            :color="showMap ? 'primary' : undefined"
            @click="
              showTable = false;
              showMap = true;
            "
            >mdi-card-account-details</v-icon
          >
        </div>
      </v-col>
      <v-row>
        <v-col cols="12">
          <Klantentabel v-if="showTable && !showMap" />
          <KlantCard v-if="!showTable && showMap" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Klantentabel from "../klanten/Klantentabel.vue";
import KlantCard from "./Klantcard.vue";
export default {
  components: {
    Klantentabel,
    KlantCard,
  },
  data: () => ({
    showTable: true,
    showMap: false,
  }),
  methods: {
    klantaanmaken() {
      this.$router.push("/Klanttoevoegen");
    },
  },
};
</script>

<style scoped>
.space {
  margin: 3px;
}
.btn {
  color: white;
}</style>
